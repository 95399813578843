<template>
  <div class="search-form">
    <CountrySelect
      @country-selected="$emit('country-selected')"
      @go-searching="$emit('go-searching', $event)"
    />
  </div>
</template>

<script>
import CountrySelect from "@/components/CountrySelect";

export default {
  name: "SearchForm",
  components: { CountrySelect },
};
</script>