<template>
  <div class="country-lists-flags">
    <div class="search-block form-field" :class="{ hasValue: searchPhrase }">
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0257 14.3475L18.5948 17.9158L17.4157 19.095L13.8473 15.5258C12.5196 16.5902 10.8682 17.1691 9.1665 17.1667C5.0265 17.1667 1.6665 13.8067 1.6665 9.66666C1.6665 5.52666 5.0265 2.16666 9.1665 2.16666C13.3065 2.16666 16.6665 5.52666 16.6665 9.66666C16.6689 11.3683 16.09 13.0198 15.0257 14.3475ZM13.354 13.7292C14.4116 12.6416 15.0022 11.1837 14.9998 9.66666C14.9998 6.44332 12.389 3.83332 9.1665 3.83332C5.94317 3.83332 3.33317 6.44332 3.33317 9.66666C3.33317 12.8892 5.94317 15.5 9.1665 15.5C10.6835 15.5024 12.1414 14.9118 13.229 13.8542L13.354 13.7292Z"
          fill="#7D7E8B"
        />
      </svg>
      <input type="text" ref="searchField" v-model="searchPhrase" />
      <label>{{ translations("EnterCountryName") }}</label>
    </div>
    <div class="countries-block-area populap">
      <p v-if="!searchPhrase.length" class="grey">
        {{ translations("PopularQueries") }}
      </p>
      <small
        class="grey"
        v-if="
          !searchPhrase.length && !popularFilteredCountries(searchPhrase).length
        "
      >
        {{ translations("SearchNoResults") }}
      </small>
      <ul
        class="country-items"
        v-if="
          !searchPhrase.length && popularFilteredCountries(searchPhrase).length
        "
      >
        <li
          v-for="country in popularFilteredCountries(searchPhrase)"
          :key="country.id"
        >
          <button
            v-if="itemType === 'button'"
            type="button"
            @click="selectCountry(country.id)"
            :class="[
              'btn-empty',
              {
                active:
                  country.id ===
                  ($route.meta.country ? curPageCountryId : curCountryId),
              },
            ]"
          >
            <img
              loading="lazy"
              :src="'//' + serverLink + country.flag"
              alt=""
            />
            {{ country.text }}
          </button>
          <router-link
            v-if="itemType === 'link'"
            :to="'/country/' + country.link_sef"
            :class="[
              'btn-empty',
              {
                active:
                  country.id ===
                  ($route.meta.country ? curPageCountryId : curCountryId),
              },
            ]"
          >
            <img
              loading="lazy"
              :src="'//' + serverLink + country.flag"
              alt=""
            />
            {{ country.text }}
          </router-link>
          <router-link
            v-if="itemType === 'tariffs'"
            :to="'/tariffs/' + country.link_sef"
            :class="[
              'btn-empty',
              {
                active:
                  country.id ===
                  ($route.meta.country ? curPageCountryId : curCountryId),
              },
            ]"
          >
            <img
              loading="lazy"
              :src="'//' + serverLink + country.flag"
              alt=""
            />
            {{ country.text }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="countries-block-area all-list">
      <p v-if="!searchPhrase.length" class="grey">
        {{ translations("AllCountries") }}
      </p>
      <small
        class="grey"
        v-if="
          !searchPhrase.length && !allFilteredCountries(searchPhrase).length
        "
      >
        {{ translations("SearchNoResults") }}
      </small>
      <ul
        class="country-items"
        v-if="allFilteredCountries(searchPhrase).length"
      >
        <li
          v-for="country in allFilteredCountries(searchPhrase)"
          :key="country.id"
        >
          <button
            v-if="itemType === 'button'"
            type="button"
            @click="selectCountry(country.id)"
            :class="[
              'btn-empty',
              {
                active:
                  country.id ===
                  ($route.meta.country ? curPageCountryId : curCountryId),
              },
            ]"
          >
            <img
              loading="lazy"
              :src="'//' + serverLink + country.flag"
              alt=""
            />
            {{ country.text }}
          </button>
          <router-link
            v-if="itemType === 'link'"
            :to="'/country/' + country.link_sef"
            :class="[
              'btn-empty',
              {
                active:
                  country.id ===
                  ($route.meta.country ? curPageCountryId : curCountryId),
              },
            ]"
          >
            <img
              loading="lazy"
              :src="'//' + serverLink + country.flag"
              alt=""
            />
            {{ country.text }}
          </router-link>
          <router-link
            v-if="itemType === 'tariffs'"
            :to="'/tariffs/' + country.link_sef"
            :class="[
              'btn-empty',
              {
                active:
                  country.id ===
                  ($route.meta.country ? curPageCountryId : curCountryId),
              },
            ]"
          >
            <img
              loading="lazy"
              :src="'//' + serverLink + country.flag"
              alt=""
            />
            {{ country.text }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import { VUE_APP_BACK_URL } from "@/config";

export default {
  name: "CountryPanels",
  components: {},
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      searchPhrase: "",
    };
  },
  props: {
    search: {
      type: Boolean,
      default: true,
    },
    itemType: {
      type: String,
      default: "link",
    },
  },
  computed: {
    ...mapGetters([
      "allFilteredCountries",
      "popularFilteredCountries",
      "curCountryId",
      "curPageCountryId",
      "isCountriesLoaded",
      "isCountriesLoading",
      "translations",
    ]),
  },
  watch: {
    selectedCountryId: function (newValue) {
      this.setCurCountryId(newValue);
    },
    searchPhrase(v) {
      //      this.$nextTick(() => {
      this.searchPhrase = String(v).replace(/\d/g, "");
      //      });
    },
  },
  methods: {
    ...mapActions([
      "fetchCountries",
      "setCurCountryId",
      "setCurPageCountry",
      "setCurCountryName",
    ]),
    async selectCountry(id) {
      await this.setCurCountryId(id);
      this.$emit("country-select");
    },
  },
  async mounted() {
    if (!this.isCountriesLoaded && !this.isCountriesLoading) {
      await this.fetchCountries({
        lang: this.$cookies.get("user_language"),
        country: this.$route.meta.country ? this.$route.path : "",
      });
    } else if (this.$route.meta.country) {
      await this.setCurPageCountry(this.$route.path);
    }
    this.$refs.searchField.focus();
  },
};
</script>