<template>
  <div>
    <div v-if="!productsLoaded" class="flex-loader-container">
      <Loader />
    </div>
    <div class="products-list" v-else>
      <div
        class="product"
        v-for="product in listProducts(productType)"
        :key="product.id"
      >
        <div class="product-heading">
          <div
            class="image"
            v-if="product.image_type !== 'pdf'"
            @click="showPopup(product.id)"
          >
            <img :src="'//' + serverLink + product.smallThumb" alt="" />
          </div>
          <div class="image pdf-link" v-if="product.image_type === 'pdf'">
            <a :href="'//' + serverLink + product.image" target="_blank"
              ><img :src="'//' + serverLink + product.smallThumb" alt=""
            /></a>
          </div>
          <div class="name" @click="expandProduct(product.id)">
            <div>{{ product.name }}</div>
            <span class="link open-products" :class="{ expanded: product.open }"
              ><span v-if="!product.open">{{ translations("Expand") }}</span
              ><span v-if="product.open">{{ translations("Shrink") }}</span
              ><svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.9998 9.00056L11.1991 5.8L12.7998 7.39971L7.9998 12.2L3.1998 7.39971L4.80056 5.8L7.9998 9.00056Z"
                  fill="#4C69E8"
                />
              </svg>
            </span>
          </div>
          <div class="duration" @click="expandProduct(product.id)">
            {{ product.duration.text }}
            {{
              workingDays[
                pluralType(product.duration.to, $cookies.get("user_language"))
              ]
            }}
            <span class="mobile-visible">
              <br />
              €{{ product.price }}<br />
              {{
                numberFormat(
                  Math.ceil(
                    product.priceNum * parseFloat(listSettings.rubleCourse)
                  ),
                  0,
                  "",
                  "&nbsp;"
                )
              }}&nbsp;₽
            </span>
          </div>
          <div class="price euro-price" @click="expandProduct(product.id)">
            €{{ product.price }}
          </div>
          <div class="price ruble-price" @click="expandProduct(product.id)">
            {{
              numberFormat(
                Math.ceil(
                  product.priceNum * parseFloat(listSettings.rubleCourse)
                ),
                0,
                "",
                "&nbsp;"
              )
            }}&nbsp;₽
          </div>
          <button
            v-if="companyId"
            type="button"
            :disabled="cartButtonDisabled(listCompany(companyId).name, product.id)"
            class="btn btn-blue"
            @click="AddProductToCart({ companyId, productId: product.id })"
          >
            <span>
              {{
                cartButtonDisabled(listCompany(companyId).name, product.id)
                  ? translations("InCart")
                  : translations("ToCart")
              }}</span
            >
          </button>
        </div>
        <transition
          @enter="transitionStep1"
          @after-enter="transitionStep2"
          @before-leave="transitionStep3"
          @after-leave="transitionStep4"
          name="faq-accordion"
        >
          <div class="product-details" v-if="product.open">
            <div
              class="image"
              v-if="product.image_type !== 'pdf'"
              @click="showPopup(product.id)"
            >
              <img :src="'//' + serverLink + product.thumb" alt="" />
            </div>
            <div class="image pdf-link" v-if="product.image_type === 'pdf'">
              <a :href="'//' + serverLink + product.image" target="_blank"
                ><img :src="'//' + serverLink + product.thumb" alt=""
              /></a>
            </div>
            <div class="description" v-html="product.description"></div>
          </div>
        </transition>
      </div>
      <Popuper
        v-if="showModal && selectedProduct.image"
        :contentType="'image'"
        :width="'auto'"
        @close="showModal = false"
        @update-list="$emit('update-list')"
      >
        <template v-slot:body>
          <img :src="'//' + serverLink + selectedProduct.image" alt="" />
        </template>
      </Popuper>
      <Popuper v-if="showAdded" :width="'700px'" @close="showAdded = false">
        <template v-slot:header>
          <h3>{{ translations("ProductAddedToCart") }}</h3>
        </template>
        <template v-slot:body>
          <div class="cart-product">
            <div class="image small-thumb">
              <img
                :src="'//' + serverLink + listCurProduct.smallThumb"
                :alt="listCurProduct.name"
              />
            </div>
            <div class="details">
              <div>
                <p class="name">{{ listCurProduct.name }}</p>
              </div>
            </div>
            <div class="price">
              <span class="euro">€{{ listCurProduct.price }}</span>
            </div>
          </div>
          <div class="buttons-line-area">
            <button
              type="button"
              class="btn btn-blue btn-big"
              @click="showAdded = false"
            >
              <span>{{ translations("ContinueShopping") }}</span>
            </button>
            <button
              type="button"
              @click="startCheckout"
              class="btn btn-blue btn-big"
            >
              <span>{{ translations("Checkout") }}</span>
            </button>
          </div>
          <div
            class="products-related"
            v-if="listCurProduct.productsRelated.length"
          >
            <h4>{{ translations("RelatedWithThisProduct") }}</h4>
            <div class="products-list">
              <div
                class="cart-product"
                v-for="related in listRelatedProducts(listCurProductId)"
                :key="related.id"
              >
                <div class="image small-thumb">
                  <img
                    :src="'//' + serverLink + related.smallThumb"
                    :alt="related.name"
                  />
                </div>
                <div class="details">
                  <p class="name">{{ related.name }}</p>
                </div>
                <div class="price">
                  <button
                    v-if="companyId"
                    type="button"
                    :disabled="cartButtonDisabled(listCompany(companyId).name, related.id)"
                    class="btn btn-blue"
                    @click="
                      AddProductToCart({ companyId, productId: related.id })
                    "
                  >
                    <span>
                      {{
                        cartButtonDisabled(listCompany(companyId).name, related.id)
                          ? translations("InCart")
                          : translations("ToCart")
                      }}
                      - €{{ related.price }}</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Popuper>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL, numberFormat, pluralType } from "@/config";
import Loader from "@/components/app/Loader";
import Popuper from "@/components/app/Popuper";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProductList",
  components: { Loader, Popuper },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      pluralType,
      products: [],
      numberFormat,
      selectedProduct: {},
      showModal: false,
      addingToCart: false,
      showAdded: false,
      workingDays: [],
    };
  },
  props: {
    productType: {
      type: Number,
      default: 0,
    },
    companyId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters([
      "translations",
      "curCountry",
      "listSettings",
      "typePurposesLoaded",
      "listTypePurposes",
      "productsLoaded",
      "listProducts",
      "isProductOpen",
      "selectProduct",
      "listCurProduct",
      "listCurProductId",
      "listRelatedProducts",
      "cartButtonDisabled",
      "listCompany",
      "isUserLogged",
      "ListLoggedUser",
      "curTypePurpose",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchTypePurposes",
      "fetchProducts",
      "toggleProductOpen",
      "setCurProduct",
      "addToCart",
    ]),
    expandProduct(productId) {
      if (!this.isProductOpen(productId)) {
        const product = this.selectProduct(productId);

        if (this.ListLoggedUser.boughts === 0) {
          window.dataLayer.push({
            ecommerce: {
              currencyCode: "EUR",
              detail: {
                products: [
                  {
                    id: product.id,
                    name: product.name,
                    price: parseFloat(parseFloat(product.priceNum).toFixed(2)),
                    brand: this.curTypePurpose.name
                      ? this.curTypePurpose.name
                      : "",
                    category: this.curCountry ? this.curCountry.text : "",
                    variant:
                      "Доставка " + (product.delivery ? "" : "не ") + "нужна",
                  },
                ],
              },
            },
          });

          this.$metrika.reachGoal("productDetails", {
            product: product.name,
          });
        }
      }

      this.toggleProductOpen(productId);
    },
    async AddProductToCart({ companyId, productId }) {
      if (!this.addingToCart) {
        this.addingToCart = true;
        if (!this.showAdded) {
          await this.setCurProduct(productId);
        }
        console.log("добавляем в корзину: ", this.listCurProductId);
        let cartIds = localStorage.getItem("cart_ids");
        if (cartIds) {
          cartIds = JSON.parse(cartIds);
        } else {
          cartIds = [];
        }
        let customerId = 0;
        if (this.isUserLogged) {
          customerId = this.ListLoggedUser.id;
        }
        let company = this.listCompany(companyId);

        const selectedProduct = this.selectProduct(productId);
        const price =
          selectedProduct.priceNum * parseFloat(this.listSettings.rubleCourse);

        await this.addToCart({
          lang: this.$cookies.get("user_language"),
          customerId,
          company,
          productId,
          price,
          cartIds,
        });
        this.addingToCart = false;
        this.showAdded = true;

        if (this.ListLoggedUser.boughts === 0) {
          window.dataLayer.push({
            ecommerce: {
              currencyCode: "EUR",
              add: {
                products: [
                  {
                    id: selectedProduct.id,
                    name: selectedProduct.name,
                    price: parseFloat(
                      parseFloat(selectedProduct.priceNum).toFixed(2)
                    ),
                    brand: this.curTypePurpose.name
                      ? this.curTypePurpose.name
                      : "",
                    category: this.curCountry ? this.curCountry.text : "",
                    quantity: 1,
                  },
                ],
              },
            },
          });

          this.$metrika.reachGoal("addProductToCart", {
            productName: selectedProduct.name,
            productPriceEur: parseFloat(
              parseFloat(selectedProduct.priceNum).toFixed(2)
            ),
            productPriceRub: parseFloat(parseFloat(price).toFixed(2)),
            productDelivery: selectedProduct.delivery,
            productDuration: selectedProduct.duration.text,
          });
        }
      }
    },
    showPopup(productId) {
      this.selectedProduct = this.selectProduct(productId);
      if (this.selectedProduct.image) {
        this.showModal = true;
      }
    },
    startCheckout() {
      this.showAdded = false;
      this.$router.push("/checkout");
    },
    transitionStep1(el) {
      // set the block height at the moment of its appearance
      el.style.height = el.scrollHeight + 25 + "px";
    },

    transitionStep2(el) {
      // remove inline styles from the block after animation of its appearance
      el.style.height = el.scrollHeight + "px";
    },

    transitionStep3(el) {
      // set the height of the block at the beginning of its disappearance animation
      el.style.height = el.scrollHeight + 25 + "px";
    },

    transitionStep4(el) {
      // remove inline styles from the block after the animation of its disappearance
      el.style.height = el.scrollHeight + "px";
    },
  },
  created() {
    this.workingDays = this.translations("PluralWorkingDays");
    this.workingDays = JSON.parse(this.workingDays.trim());
  },
  async mounted() {},
};
</script>