<template>
  <div class="country-search-area" :class="{ opened: popupOpen }">
    <transition name="fade" appear>
      <div class="country-select" v-if="isCountriesLoaded">
        <div
          class="selected-country"
          :class="{ open: popupOpen }"
          @click="popupOpen = !popupOpen"
        >
          <span class="mob-choose">{{ translations("SelectCountry") }}</span>
          <img :src="'//' + serverLink + curCountry.flag" alt="" />
          <span class="desc-country" v-if="isCountriesLoaded">{{
            curCountry.text
          }}</span>
          <span class="mob-country" v-if="isCountriesLoaded">{{
            curCountry.code
          }}</span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 8.50062L11.3325 5L13 6.74969L8 12L3 6.74969L4.66745 5L8 8.50062Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div class="search-block">
          <input
            type="text"
            ref="mainSearchInput"
            :placeholder="placeholder"
            @keyup.enter="goSearch"
            v-model="searchQuery"
          />
        </div>
        <div class="info-block" style="display: none">
          <v-popover class="inline">
            <button class="popup">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4ZM7.2 4H8.8V5.6H7.2V4ZM7.2 7.2H8.8V12H7.2V7.2Z"
                  fill="#7D7E8B"
                />
              </svg>
            </button>
            <span
              class="popup-hidden"
              v-if="isCountriesLoaded"
              v-html="curCountry.text"
              slot="popover"
              >{{ curCountry.text }}</span
            >
          </v-popover>
        </div>
        <button type="button" class="btn btn-blue btn-big" @click="goSearch">
          <span class="title">{{ translations("Find") }}</span>
          <span class="icon"
            ><svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 14.1667C11.555 14.1667 14.1667 11.555 14.1667 8.33333C14.1667 5.11167 11.555 2.5 8.33333 2.5C5.11167 2.5 2.5 5.11167 2.5 8.33333C2.5 11.555 5.11167 14.1667 8.33333 14.1667Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 17.5L12.5 12.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </button>
      </div>
    </transition>
    <transition name="countries-slide">
      <div class="countries-popup" v-if="isCountriesLoaded && popupOpen">
        <div class="popup-inner">
          <div class="popup-scroll">
            <CountryPanels
              :itemType="'button'"
              :search="false"
              @country-select="countrySelected"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import { VUE_APP_BACK_URL, toastAlert, strippedContent } from "@/config";
import CountryPanels from "@/components/egrul/CountryPanels";

export default {
  name: "CountrySelect",
  components: { CountryPanels },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      strippedContent,
      popupOpen: false,
      searchQuery: "",
      placeholder: "",
    };
  },
  computed: {
    ...mapGetters([
      "curCountry",
      "isCountriesLoaded",
      "isCountriesLoading",
      "ListLoggedUser",
      "translations",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchCountries",
      "setCurCountryId",
      "setCurCountryByCode",
      "setCurPageCountry",
    ]),
    clickHandler(event) {
      const { target } = event;
      const { $el } = this;
      if (!$el.contains(target)) {
        this.popupOpen = false;
      }
    },
    resizeHandler() {
      if (window.innerWidth > 576) {
        this.placeholder = this.translations("EnterCompanyInformation");
      } else {
        this.placeholder = this.translations("EnterCompanyInformationShort");
      }
    },
    countrySelected() {
      this.popupOpen = false;
      toastAlert(this.translations("CountryChosen", [this.curCountry.text]));
      this.$refs.mainSearchInput.focus();
      this.$emit("country-selected", "123");
    },
    goSearch() {
      let fullpath = "/search";
      console.log("this.searchQuery: ", this.searchQuery);
      /*
      const curSearch = this.strippedContent(
        decodeURIComponent(this.$route.query.query || "")
      );
      const curCountry = this.strippedContent(
        decodeURIComponent(this.$route.query.country || "")
      );
      */
      if (
        //        (this.searchQuery.length && curSearch !== this.searchQuery) ||
        //        curCountry != this.curCountry.code.toLowerCase()
        this.searchQuery.length
      ) {
        this.searchQuery = this.strippedContent(this.searchQuery || "");
        fullpath += `?country=${this.curCountry.code.toLowerCase()}&query=${encodeURIComponent(
          this.searchQuery
        )}`;

        if (this.ListLoggedUser.boughts === 0) {
          this.$metrika.reachGoal("searchPressed", {
            searchCountryСode: this.curCountry.code,
            searchCountryName: this.curCountry.text,
            searchQuery: this.searchQuery,
          });
        }

        this.$router.push(fullpath);
        this.$emit("go-searching", this.searchQuery);
      }
    },
  },
  watch: {
    isCountriesLoaded(val) {
      if (val === true) {
        setTimeout(() => {
          this.$refs.mainSearchInput.focus();
        }, 500);
      }
    },
  },
  async mounted() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
    document.addEventListener("click", this.clickHandler);
    this.searchQuery = this.$route.query.query || "";
    if (!this.isCountriesLoaded && !this.isCountriesLoading) {
      await this.fetchCountries({
        lang: this.$cookies.get("user_language"),
        country: this.$route.meta.country ? this.$route.path : "",
        countryCode: this.$route.query.country ? this.$route.query.country : "",
      });
    } else if (this.$route.meta.country) {
      console.log("setting page country");
      await this.setCurPageCountry(this.$route.path);
    }

    if (this.isCountriesLoaded) {
      this.$refs.mainSearchInput.focus();
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickHandler);
  },
};
</script>